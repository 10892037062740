@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,400;1,600&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300&display=swap');

* {
    margin: 0;
    padding: 0;
    
}

.cards {
    display: flex;

 
    align-items: center;
    justify-content: center;

  
}

.card {
    padding-top: 8px;
    margin: 1rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 4rem;
    width: 17.69388rem;
    height: 16.547rem;
    flex-shrink: 0;
    border-radius: 2.4375rem;
    background: linear-gradient(140deg, rgba(237, 237, 237, 0.06) 0%, rgba(217, 217, 217, 0.05) 100.6%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

Nav {
    width: 95%;
    margin-left: 2.5%;
}

.head {
    color: #FFF;
    font-family: Nunito;
    font-size: 3.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.p {
    color: #FFF;
    font-family: Nunito;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 1.3rem;
    margin-bottom: 1.5rem;
}
.mobile{
    margin-top: 5rem;
}
.btn {
    width: 15.4375rem;
    padding-top: 0.8rem;
    flex-shrink: 0;
    border-radius: 2.5625rem;
    background: #FFF;

    color: #000;
    padding-bottom: 0.8rem;
    text-align: center;
    font-family: Nunito;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}

.gg {
    margin-bottom: 2.5rem;
}

.sv{
    width: 3.75rem;
    margin-top: -1rem;
height: 3.75rem;
flex-shrink: 0;
margin-right: 0rem;
}

.tag{
    color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 1.5625rem;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 1.2rem;
margin-bottom: 1.2rem;
}

.desc{
    width: 80%;
    color: #D4D4D4;
text-align: center;
font-family: Nunito;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.heading{
    color: #FFF;
text-align: center;
font-family: Nunito;
font-size: 2.375rem;
font-style: normal;
margin-top: 4rem;
font-weight: 700;
line-height: normal;
}

.step{
    display: flex;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 8rem;
    justify-content: center;
}

.s1{
    width: 12.875rem;
height: 25.75rem;
margin-right: 3rem;
flex-shrink: 0;
}

svg{
    width: 3rem;
    margin-right: 1rem;
}

.tagS{
    color: #FFF;
font-family: Nunito;
font-size: 2rem;
font-style: normal;
font-weight: 800;
line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stepS{
    color: #858585;
font-family: Nunito;
font-size: 1.6rem;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 2rem;
margin-bottom: 2rem;
}

.btnS{
    width: 12rem;
    color: #000;
    text-align: center;
    font-family: Nunito;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 2.5625rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
background: #FFF;
}

.wrap{
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: start;
    justify-content: start;
}

.join{
    width:90%;
}

@media screen and (max-width:1000px) {
   .for{
    width: 100vw !important;
   }
    .cards{
        flex-direction: column !important;
    }
    .step{
flex-direction: column;
zoom: 90%;
    }
    .wrap{
        align-items: center;
        width:90%;
        justify-content: center;
        zoom: 70%;
        
    }
    .s1{
        margin-right: 0rem;
        zoom: 90%;
    }
    .mobile{
        width: 100%;
        display: flex;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        margin-top: 5rem;
        text-align: center;
        zoom: 90%;
    }
    .btn{
        width: 90%;
        margin-left: 5%;
    }
    .less{
        margin-top: -4rem;
    }
  .s1{
    zoom: 80%;
  }
  .step{
    display: flex;
    flex-direction: column;
    align-content: center !important;
    justify-content: center !important;
  }
  .wrap{
    display: flex;
    flex-direction: column;
    align-content: center !important;
    justify-content: center !important;
    text-align: center;
    zoom: 65%;
  }
  .al{
    text-align: center;
  }
  .tagS{
    font-size: 1.7rem;
  }
  .btnS{
width: 80%;
margin-left: 9%;
  }
  
}

.ss{
    margin-left: 0.5rem;
}

.ahh{
    margin-left: 0.5rem !important;
}

@media  screen and (max-width:400px) {
    .stepS{
        font-size: 1.4rem;
    }
    .tagS{
        font-size: 1.1rem;
        margin-top: 1rem;
    }
    .s1{
        height: 550px;
    }
    .btnS{
        margin-left: -0.25rem;
    }
}

