.menu {
    display: flex;
    flex-direction: column;
    align-items: start;
    position: fixed;
    justify-content: space-between;
    top: 0%;
    right: -10%; /* Initially off-screen to the right */
    height: 100%;
    width: 0px; /* Initially hidden */
    border-radius: 1.875rem;
    border: 1px solid #000;

    padding: 5rem;
    background: #000000c4;

    backdrop-filter: blur(100px);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.68, -0.25, 0.27, 1.25); /* Elastic ease-in-out transition */
    z-index: -1;
  }
  
  .menu.open {
    width: 50%;
    opacity: 1;
    z-index: 1;
    right: 0%;
  }
  

@media screen and (max-width:600px) {
  .menu{
    padding-left: 2rem;
    padding-right: 1rem;
   padding-top: 4rem;
   padding-bottom: 4rem;
    right:20%;
    height: 100%;
    border-radius: 0;
  }

  .menu.open{
    width: 80%;
  }
  .lin{
    font-size: 1.3rem !important;
  }
}

  .closeButton {
    border-radius: 1.625rem;
border: 2px solid #FFF;
background: rgba(37, 37, 37, 0.00);
color: #FFF;
padding-top: 0.8rem;
padding-bottom: 0.8rem;
padding-left: 1.8rem;
padding-right: 1.8rem;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: normal;
transition: 0.3s all;
  }
  
  .closeButton:hover {
    background-color: white;
    color: black;
  }
  .lin{
    color: #ffffffda;
font-family: Nunito;
font-size: 2rem;
font-style: normal;
font-weight: 700;
line-height: normal;
transition: 0.2s ease all;
  }

  .lin:hover{
    color: white;
 transform: scale(1.05);

  }

  .nigga{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .nigI{
    width: 30px;
  }
.fff{
  width: 25px;
}
  .clusterI{
    display: flex;
    gap: 30px;
  }

  .fc{
    margin-top: -4px;
  }