@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&display=swap');

*{
  /* transition: all 1s; */
  font-family: "Instrument Sans", sans-serif !important;
  scroll-behavior: smooth;
  -webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
-o-user-select: none;
user-select: none;
}
body {
  font-family: "Instrument Sans", sans-serif !important;
  background-color: #0D0D0D;
  margin: 0;

}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

.cursor-pointer{
  z-index: 1;
}

.slick-track {
  display: flex !important;
  align-items: stretch !important;
}

.slick-slide {
 display: flex !important;
 align-self: stretch !important;
 height: unset !important;
}

.slick-slide > div {
  display: flex !important;
  align-self: stretch !important;
  width: 100% !important;
}

@media screen and (min-width: 900px){
  .appear{
    display: none;
  }
}

@media screen and (max-width:900px) {
  .here{
    zoom: 80%;
  }
  .small{
    zoom: 90%;
    margin-left: 5%;
  }
  .small1{
  margin-bottom: 2rem;
  }
  .remove{
    display: none;
  }
  .idk{
    flex-direction: row !important;
  }
  .bruh{
    
  }

  .mobP{
width: 100vw !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  margin-left: -0.5rem;

  }
 .mob{
  margin-left: 1rem;
  width: 14rem ;
  zoom: 70%;
  font-size: 1rem !important;
 }
 .fuckthis{
  width: 80%;
  margin-left: 10%;
 }

  .fuck{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
width: 100%;
  }
  .mobP{
    margin-left: -16%;
  }
  .new{
    width: 100%  !important;
    align-items: flex-start !important;
    justify-content: start !important;
  }
  .rev{
    flex-direction: column-reverse !important;
  }

  .very{
    zoom: 60%;
  }
 .bottom{
  margin-bottom: 2rem;
 }
 .newS{
  zoom: 90%;
 }
 .bit{
  width: 17rem;
  text-align: center;
 }
}

.nav{
  /* backdrop-filter: blur(600.399999618530273px); */
  padding-right: 3rem !important;
  padding-left: 3rem !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  margin-left: -0.5rem;
}
.title{
  margin-top: 8rem;
}

.ahh{
 margin-left: 0.25rem !important;
}



@media screen and (max-width:500px) {
.fuckthis{
  width: 80%;
  margin-left: 10%;
}
.mobP{
  margin-left: -17%;
}
.noo{
  width: 100%;

}
.man{
  font-size: 1rem;
}
.mob{
width: 40%;
}
}

@media screen and (max-width:400px){
  .man{
    font-size: 0.7rem;
  }
}

.butt{
  z-index: 1 !important;
  cursor: pointer;
  position: relative;
  border: 2px solid white;
  transition: 0.5s ease all;
}

.butt:hover{
  background-color: #0d0d0d00;
  color:white;
  border: 2px solid white;
  transition: 0.5s ease all;
 transform: scale(1.1);
}

.butt2:hover{
  background-color: #0d0d0d00;
  color:black;
  border: 2px solid black;
  transition: 0.5s ease all;
}

.butt3:hover{
  border: 2px solid black;
  background-color: white;
  color: black;
}

.ic{
  width: 200px;
height: 250px;
flex-shrink: 0;
border-radius: 15px;
}

.element{
  margin-top: 3rem;
}

.cc{
  width: 300px;
  height: 350px;
  padding-top: 1rem;
  flex-shrink: 0;
  border-radius: 15px;
  background-repeat: no-repeat;
 
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.75);
  background-position: center center;
  background-image: url("https://media.discordapp.net/attachments/874259441384574976/1194229297947357234/image.png?ex=65af97b4&is=659d22b4&hm=452169fe167da8d154a47eea9d745bdfedcd04eb6ab37fc1540dfb8cd5f03f03&=&format=webp&quality=lossless&width=516&height=603");
}

.name{
 
color: #FF5F1F;

text-align: center;
font-family: DM Sans;
font-size: 1.4rem;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.position{
  color: #FFF;
text-align: center;
font-family: DM Sans;
font-size: 1.2rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.work{
  color: rgb(207, 207, 207);
text-align: center;
width: 85%;
margin-left:3%;
font-family: DM Sans;
font-size: 0.8rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}


.nahh{
  justify-content: space-evenly;
}

.react{
  background-image: url("https://media.discordapp.net/attachments/874259441384574976/1194626522011684974/image.png?ex=65b109a5&is=659e94a5&hm=2dd326fa03d2f158cecafaa5961aea7b1f880f5fa1f80af346dedb74ea5e0d48&=&format=webp&quality=lossless&width=516&height=603");
}

.king{
  
  background-image: url("https://media.discordapp.net/attachments/847468967110508545/1194626746247544973/image.png?ex=65b109db&is=659e94db&hm=d47cd29f92075c056d8e88c6cf2f44558ad2717e0d061dd1ace436e4efd571af&=&format=webp&quality=lossless&width=516&height=603");
}

.chong{
  background-image: url("https://media.discordapp.net/attachments/847468967110508545/1194627282036334633/image.png?ex=65b10a5b&is=659e955b&hm=9c93cf0c3da2b370d23efaf19effa6b96bbd3c741116b0681c99d51db745eb44&=&format=webp&quality=lossless&width=516&height=603");
}

.alpha{
  background-image: url("https://media.discordapp.net/attachments/847468967110508545/1194627479646765127/image.png?ex=65b10a8a&is=659e958a&hm=e18380f11642f572defe80aae2d81a34e3b5525aaa046ed0839ff5da3aec4f3e&=&format=webp&quality=lossless&width=516&height=603");
}

.neil{
  background-image: url("https://media.discordapp.net/attachments/847468967110508545/1194627754520481852/image.png?ex=65b10acb&is=659e95cb&hm=3f2199290ccd6bcca3aa55d732d710159d21529fb985db40da99406a07b9631e&=&format=webp&quality=lossless&width=516&height=603");
}

.sherlock{
  background-image: url("https://media.discordapp.net/attachments/847468967110508545/1194627932392525884/image.png?ex=65b10af6&is=659e95f6&hm=a604ade93aab2d3c31788446e5bdeb7e022b7f669f1a3b6cf1546bd0681bb8f0&=&format=webp&quality=lossless&width=516&height=603");
}

.otw{
  background-image: url("https://media.discordapp.net/attachments/847468967110508545/1194628217487753226/image.png?ex=65b10b3a&is=659e963a&hm=c734db88f5c53939b0efd6567f19d38f4e6340fe9b5d1d12c0a90395ae3e7eee&=&format=webp&quality=lossless&width=516&height=603");
}

.mart{
  background-image: url("https://media.discordapp.net/attachments/847468967110508545/1194628416230666320/image.png?ex=65b10b69&is=659e9669&hm=b91276598230b163d53cddce93a612fad6108eae29194f31c46d567314462faa&=&format=webp&quality=lossless&width=516&height=603");
}

.xi{
  background-image: url("https://media.discordapp.net/attachments/847468967110508545/1194628605616078889/image.png?ex=65b10b96&is=659e9696&hm=4529e43eda1041e81cfd42ac8867d75f3101fd7eb8815b6effaf575aa91912f1&=&format=webp&quality=lossless&width=516&height=603");
}

.arsh{
  background-image: url("https://media.discordapp.net/attachments/847468967110508545/1194628822750998608/image.png?ex=65b10bca&is=659e96ca&hm=fb8d7fb1b625e6fa1f9b2b34de4469685f9b1d24ed247b49344605a528a6437e&=&format=webp&quality=lossless&width=516&height=603");
}
.weather{
  background-image: url("https://media.discordapp.net/attachments/1150421752028090519/1196805881229217902/image.png?ex=65b8f755&is=65a68255&hm=b8652f093515fc483375236d8c4e5e89e51889f651baca0fddebc053fa67a801&=&format=webp&quality=lossless&width=514&height=603");
}
.more:hover{
  transform: scale(1.1);
  transition: 1s all ease;
}

.more{
  transition: 1s all ease;
}

.sh{
  font-weight: 600;
}
.cl{
background-color: transparent;
border:2px solid white;
transition: 0.4s ease all;
}

.cl{
  transition: 0.4s ease all;
}
/* Maintance snippet */
/* .right{
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;
  object-fit: cover;
  backdrop-filter: blur(1000px);
  box-shadow: 10px 10px 150px 2px rgba(255, 255, 255, 0.082);

}
.tweak{
  width: 100%;
} */


@media screen and (max-width:800px) {
  .brodie{
  width: 180px;

  padding-top: 0.5rem;  padding-bottom: 0.5rem;
  margin-top: -11rem;
  }
}

.news{
  background: linear-gradient(140deg, rgba(0, 0, 0, 0.06) 0%, rgba(199, 199, 199, 0.05) 100.6%);
}

.t1{
  background-image: url("../../public/images/t1.webp");
}
.t2{
  background-image: url("../../public/images/t2.webp");
}
.tx{
  background-image: url("https://media.discordapp.net/attachments/1150421752028090519/1217115546059931749/ALERISLOGO-02.png?ex=6602da2f&is=65f0652f&hm=76bf8663af6aa3c516b983b424077b273b482cf1578c7f1f5ec24bcfe5ca68bf&=&format=webp&quality=lossless&width=603&height=603");
}
.t3{
  background-image: url("../../public/images/t3.webp");
}
.t4{
  background-image: url("../../public/images/t4.webp");
}
.t5{
  background-image: url("../../public/images/t5.webp");
}
.t6{
  background-image: url("../../public/images/t6.webp");
}
.t7{
  background-image: url("../../public/images/t7.webp");
}
.t8{
  background-image: url("../../public/images/t8.webp");
}
.t9{
  background-image: url("../../public/images/t9.webp");
}
.t10{
  background-image: url("../../public/images/t10.webp");
}
.t11{
  background-image: url("../../public/images/t12.webp");
}
.t12{
  background-image: url("../../public/images/t11.webp");
}
.t13{
  background-image: url("../../public/images/t13.webp");
}
.t14{
  background-image: url("../../public/images/t14.webp");
}
.t15{
  background-image: url("../../public/images/t15.webp");
}
.scient{
  background-image: url("../../public/images/Scient.png");
}
.seb{
  background-image: url("../../public/images/seb.png");
}
.tip{
  background-image: url("../../public/images/tip.png");
}
.gras{
  background-image: url("../../public/images/gras.png");
}
.certa{
  background-image: url("../../public/images/Certa.png");
}
.waalg{
  background-image: url("../../public/images/Waalg.png");
}
.wil{
  background-image: url("../../public/images/Wilsauce.png");
}
.xt{
  background-image: url("../../public/images/Xt.png");
}
.duck{
  background-color: #0d0d0dcc;
  padding-top:1rem;
  padding-bottom: 1rem;
}

#popup{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
}